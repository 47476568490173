import React, { useState, useEffect } from "react"
import Layout from "../../../../components/layout"
import {
  Table,
  Input,
  Popconfirm,
  Form,
  Typography,
  Space,
  Button,
  Modal,
} from "antd"
import { CpiService } from "../../../../services/cpi.service"
import firebase from "gatsby-plugin-firebase"
import PageHeading from "../../../../components/pageHeading"

function AddProv({ visible, onCreate, onCancel }) {
  const [form] = Form.useForm()
  return (
    <Modal
      visible={visible}
      title="Add a new Provenance"
      okText="Add"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            form.resetFields()
            onCreate(values)
          })
          .catch(info => {
            //console.log("Validate Failed:", info)
          })
      }}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
        }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please input the name of the provenance",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="startTime"
          label="Start Year"
          //   rules={[
          //     {
          //       pattern: new RegExp(/^[0-9]{4,4}$/),
          //       message: "The input is not a valid year",
          //     },
          //   ]}
        >
          <Input placeholder="YYYY" />
        </Form.Item>
        <Form.Item
          name="endTime"
          label="End Year"
          // rules={[
          //   {
          //     pattern: new RegExp(/^[0-9]{4,4}$/),
          //     message: "The input is not a valid year",
          //   },
          // ]}
        >
          <Input placeholder="YYYY" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  //const inputNode = inputType === "number" ? <InputNumber /> : <Input />

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
        >
          <Input />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

export default function Provenance({ id }) {
  const [modalShow, setModalShow] = useState(false)
  const [form] = Form.useForm()
  const [editingKey, setEditingKey] = useState("")

  const [provData, setProvData] = useState([])

  useEffect(() => {
    return firebase
      .firestore()
      .collection("cpi/" + id + "/provenance/")
      .onSnapshot(snapshot => {
        const data = []
        snapshot.forEach(doc => data.push({ ...doc.data(), key: doc.id }))
        setProvData(data)
      })
  }, [])

  const handleDelete = key => {
    CpiService.deleteProv(id, key)
  }

  const isEditing = record => record.key === editingKey

  const edit = record => {
    form.setFieldsValue({
      name: "",
      starttime: "",
      endtime: "",
      ...record,
    })
    setEditingKey(record.key)
  }

  const cancel = () => {
    setEditingKey("")
  }

  const save = async key => {
    try {
      const row = await form.validateFields() // individual row data

      Object.keys(row).forEach(key => row[key] === undefined && delete row[key])

      CpiService.updateProv(id, key, row)
      const newData = [...provData]
      const index = newData.findIndex(item => key === item.key)

      if (index > -1) {
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...row })
        setProvData(newData) //setting for all
        setEditingKey("")
      } else {
        newData.push(row)
        setProvData(newData)
        setEditingKey("")
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo)
    }
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "36%",
      editable: true,
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      width: "22%",
      editable: true,
      sorter: {
        compare: (a, b) => a.startTime - b.startTime,
        multiple: 2,
      },
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      width: "22%",
      editable: true,
      sorter: {
        compare: (a, b) => a.endTime - b.endTime,
        multiple: 1,
      },
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record)
        return editable ? (
          <span>
            <a
              href="javascript:;"
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </a>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Space size="middle">
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record.key)}
            >
              <a>Delete</a>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]
  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType:
          col.dataIndex === "startTime" || col.dataIndex === "endTime"
            ? "number"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  const onCreate = values => {
    values.createdAt = firebase.firestore.Timestamp.now()
    Object.keys(values).forEach(
      key => values[key] === undefined && delete values[key]
    )

    CpiService.createProv(id, values)
    const newData = [...provData]
    newData.push(values)
    setProvData(newData)
    setModalShow(false)
  }

  return (
    <Layout>
      <PageHeading title="Provenance" />
      <div className="flex flex-row justify-between p-3 pt-8">
        {/* <div className="text-lg">Provenance Table</div> */}
        <Button
          type="primary"
          onClick={() => {
            setModalShow(true)
          }}
        >
          Add
        </Button>
      </div>
      <Form form={form} component={false}>
        <Table
          className="w-screen"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={provData}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
          // onRow={r => ({
          //   onClick: () => console.log(r),
          // })}
        />
      </Form>
      <AddProv
        visible={modalShow}
        onCancel={() => {
          setModalShow(false)
        }}
        onCreate={onCreate}
      />
    </Layout>
  )
}
